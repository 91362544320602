// api
import { useGetMetadata } from '@/graphql/response/queries/metadata';
// types
import { QuestionnaireInstanceStatus, QuestionnaireMetadata } from '@/types/response';

const questionnaireMetadataGuard = (data: any): data is QuestionnaireMetadata => {
  return data && typeof data.status !== 'undefined';
};

/**
 *  QuestionnaireInstanceStatus.ExportingToScoring : scoringprocessing the questionnaire to be submitted
 *  QuestionnaireInstanceStatus.ExportedForScoring : scoring successfully processed the quesitonnaire and sent it to scoring.
 *  QuestionnaireInstanceStatus.FailedToExportToScoring : scoring failed to process successfully the quesitonnaire  (i.e. it may or may not have sent it to scoring but we were unable to process everything completely)
 */

export const useQuestionnaireVisibility = (shouldFetch = true) => {
  const { data, loading, error, refetch } = useGetMetadata(shouldFetch);
  const metadata = questionnaireMetadataGuard(data) ? data : undefined;

  const status = metadata ? metadata.status : undefined;

  const isQuestionnaireInstanceStatus = (status: unknown): status is QuestionnaireInstanceStatus => {
    return Object.values(QuestionnaireInstanceStatus).includes(status as QuestionnaireInstanceStatus);
  };

  return {
    isStatusAvailable: !!status && isQuestionnaireInstanceStatus(status),

    // Statuses where Questionnaire is NOT READ-ONLY
    isQuestionnaireEditable:
      isQuestionnaireInstanceStatus(status) &&
      [
        QuestionnaireInstanceStatus.Amendments,
        QuestionnaireInstanceStatus.InProgress,
      ].includes(status),

    // Statuses are after user clicks 'Submit' button
    isQuestionnaireSubmitted:
      isQuestionnaireInstanceStatus(status) &&
      [
        QuestionnaireInstanceStatus.Submitted,
        QuestionnaireInstanceStatus.ExportedForScoring,
        QuestionnaireInstanceStatus.FailedToExportToScoring,
        QuestionnaireInstanceStatus.ExportingToScoring,
      ].includes(status),

    // Statuses where system is OK for user to transition TO editable status
    allowQuestionnaireEnterEditableStatus:
      isQuestionnaireInstanceStatus(status) &&
      [
        QuestionnaireInstanceStatus.ExportedForScoring,
      ].includes(status),

    isQuestionnaireInAmendments:
      isQuestionnaireInstanceStatus(status) &&
      [
        QuestionnaireInstanceStatus.Amendments,
      ].includes(status),

    isQuestionnaireInProgress:
      isQuestionnaireInstanceStatus(status) &&
      [
        QuestionnaireInstanceStatus.InProgress,
      ].includes(status),

    loading,
    error,
    refetch,
  };
};
