// useSnackbar
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
// api
import { useDeleteRow, useDeleteRowPreviewMode } from '@/graphql/response/mutations/deleteRow';
// data grid
import { useQuestionnaireVisibility } from '@/hooks/response/useQuestionnaireVisibility';
import { useIsSkipSave } from '@/store/response';
// provider
// mui icons
import { DeleteOutlined } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import { GridActionsCellItem, GridRowParams } from '@mui/x-data-grid-premium';
import { useRowLogic } from '../provider/GridViewProvider';

export const ActionsCell = ({ row }: GridRowParams) => {
  const { t } = useTranslation('questionnaire', {
    keyPrefix: 'questionnaire.grid',
  });

  const { questionId, onLoading } = useRowLogic();
  const { enqueueSnackbar } = useSnackbar();
  const { isQuestionnaireInAmendments, isQuestionnaireSubmitted } = useQuestionnaireVisibility();

  const isPreview = useIsSkipSave();
  const { deleteRowPreview } = useDeleteRowPreviewMode(questionId);

  const [
    deleteRow,
  ] = useDeleteRow(questionId, row.id);

  const handleDeleteRow = async () => {
    try {
      onLoading(true);
      if (isPreview) {
        deleteRowPreview(row.id);
      } else {
        await deleteRow();
      }
    } catch (e) {
      if (e instanceof Error) {
        console.error(e.message);
        enqueueSnackbar('Could not delete row');
      } else {
        // Handle the case where the error is not an Error instance
        console.error('An unexpected error occurred', e);
        enqueueSnackbar('Could not delete row due to an unexpected error', { variant: 'error' });
      }
    } finally {
      onLoading(false);
    }
  };

  const isDisabled = !row?.isCustom || isQuestionnaireInAmendments || isQuestionnaireSubmitted;

  const actionItem = (
    <GridActionsCellItem
      data-testid="delete-row"
      icon={<DeleteOutlined />}
      label="Delete"
      onClick={handleDeleteRow}
      disabled={isDisabled}
    />
  );

  return isDisabled ? (
    <Tooltip title={t('deleteDisabledTooltip')}>
      <span>{actionItem}</span>
    </Tooltip>
  ) : (
    actionItem
  );
};
